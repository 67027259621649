import { AxiosRequestConfig, Method } from 'axios';

export interface RequestOptions {
  url: string;
  method: Method;
}

export default ({ url, method }: RequestOptions, payload: unknown): AxiosRequestConfig => {
  const req: AxiosRequestConfig = {
    url,
    method,
    responseType: 'json',
  };

  if (method === 'GET') req.params = payload;
  else req.data = payload;

  return req;
};
