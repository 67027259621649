import { DashboardState } from '../types/state';
import initialRequest from '../../common/services/handleInitialRequest';
import { tableInitialState } from '../../common/services/handleTable';

export default {
  dashboard: {
    totalUsers: { total: 0, values: [] },
    totalActiveUsers: { total: 0, values: [] },
    totalBalance: [],
    cryptoWallet: { hotWallet: [], coldWallet: [], totalBalance: 0 },
    fiatWallet: { totalBalance: 0, lamaFunds: { type: 'total', value: 0 }, clientFunds: [] },
  },
  dashboardRequest: initialRequest,
  pendingIssues: tableInitialState,
  pendingIssuesRequest: initialRequest,
  stakingPrograms: tableInitialState,
  stakingProgramsRequest: initialRequest,
  withdrawalsQueue: tableInitialState,
  withdrawalsQueueRequest: initialRequest,
  cryptoRateExchange: tableInitialState,
  cryptoRateExchangeRequest: initialRequest,
} as DashboardState;
