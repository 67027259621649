import { Action } from '../types/store/action';
import { StoreActionTypes } from '../types/store/storeActionTypes';
import initialRequest from './handleInitialRequest';

type Effects = {
  pending?: boolean;
  complete?: boolean;
  errors?: undefined | { [key: string]: any };
  success?: boolean;
  failure?: boolean;
};

type RequestEffects = 'request' | 'success' | 'failure';
type ItemByEffect = Record<RequestEffects, PayloadEffect>;
type PayloadEffect = { [key: string]: any };

const handleItemReducerEffect = <T>(
  actionType: StoreActionTypes,
  action: Action,
  state: T,
  itemEffect: PayloadEffect | ItemByEffect,
  requestEffect?: keyof T,
  requestParams?: Record<RequestEffects, Effects>,
  defaultParams?: PayloadEffect | ItemByEffect,
) => {
  if (requestEffect) {
    if (action.type === `${actionType}_REQUEST`) {
      return {
        ...state,
        ...itemEffect?.request,
        [requestEffect]: {
          ...initialRequest,
          pending: true,
          ...requestParams?.request,
        },
      };
    }

    if (action.type === `${actionType}_SUCCESS`) {
      return {
        ...state,
        ...(itemEffect?.success || itemEffect),
        [requestEffect]: {
          ...initialRequest,
          complete: true,
          success: true,
          ...requestParams?.success,
        },
      };
    }

    if (action.type === `${actionType}_FAILURE`) {
      return {
        ...state,
        ...itemEffect?.failure,
        ...defaultParams,
        [requestEffect]: {
          ...initialRequest,
          complete: true,
          failure: true,
          errors: action.payload,
          ...requestParams?.failure,
        },
      };
    }
  }

  if (action.type === actionType) {
    return {
      ...state,
      ...itemEffect,
    };
  }

  return state;
};

export default handleItemReducerEffect;
