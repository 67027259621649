import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCommon } from '../store/selectors';
import { NotificationResponse } from '../types/responses/notification';

interface NotificationPopoverHooksProps {
  handleClose: () => void;
  handleOpen: () => void;
  notifications: NotificationResponse[];
  open: boolean;
}

export default (): NotificationPopoverHooksProps => {
  const { notifications } = useSelector(selectCommon);
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return { handleClose, handleOpen, notifications, open };
};
