import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import Logo from '../../../common/components/logo';
import LoginJWT from '../../components/login';
import useLogin from '../../hooks/useLogin';
import Content from '../../../common/components/content';

const Login: FC = () => {
  const { formik, pending, schema } = useLogin();

  return (
    <Content helmetTitle="Login">
      <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>
            <RouterLink to="/">
              <Logo width={150} />
            </RouterLink>
          </Box>
          <Card>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
              <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4" data-testid="title">
                    Log in
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Log in on the internal platform
                  </Typography>
                </div>
              </Box>
              <Box sx={{ flexGrow: 1, mt: 3 }}>
                <LoginJWT pending={pending} schema={schema} {...formik} />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Content>
  );
};

export default Login;
