import { RequestProps } from '../types/store/request';

const responseInitialState: RequestProps = {
  errors: undefined,
  pending: false,
  complete: false,
  success: false,
  failure: false,
};

export default responseInitialState;
