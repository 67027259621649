export enum CashierCurrency {
  BTC = 'BTC',
}

export enum CashierDepositStatus {
  NEW = 'new',
  PAID = 'paid',
  PROCESSING = 'processing',
  CONFIRMED = 'confirmed',
  COMPLETE = 'complete',
  EXPIRED = 'expired',
  INVALID = 'invalid',
}

export enum CashierWithdrawStatus {
  NEW = 'New',
  UNCONFIRMED = 'Unconfirmed',
  CONFIRMED = 'Confirmed',
  REPLACED = 'Replaced',
}

export enum UserStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  BLOCKED = 'blocked',
}
