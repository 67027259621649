import MockAdapter from 'axios-mock-adapter/types';
import { Issue, IssueStatus, Method } from '../types/enum';
import {
  BalanceValue,
  CryptoRateExchange,
  Customer,
  Dashboard,
  PendingIssues,
  StakingPrograms,
  WithdrawalsQueue,
} from '../types/responses';
import { CurrencyTypes } from '../../common/types/currencies';

const handleMock = (mock: MockAdapter, apiPrefix: string) => {
  const btc: BalanceValue = {
    type: CurrencyTypes.BTC,
    value: 12.23,
    rate: { type: CurrencyTypes.GBP, value: 33244.3 },
  };
  const eth: BalanceValue = {
    type: CurrencyTypes.ETH,
    value: 33,
    rate: { type: CurrencyTypes.GBP, value: 2223 },
  };
  const usd: BalanceValue = {
    type: CurrencyTypes.USDT,
    value: 697,
    rate: { type: CurrencyTypes.GBP, value: 677 },
  };
  const eur: BalanceValue = {
    type: CurrencyTypes.EURO,
    value: 584,
    rate: { type: CurrencyTypes.GBP, value: 673 },
  };
  const gbp: BalanceValue = {
    type: CurrencyTypes.GBP,
    value: 493,
    rate: { type: CurrencyTypes.GBP, value: 493 },
  };
  const total: BalanceValue = {
    type: 'total',
    value: 12333,
  };
  const dashboard: Dashboard = {
    totalUsers: { total: 4321, values: [3, 6, 10, 2, 9] },
    totalActiveUsers: { total: 2334, values: [7, 10, 3, 8, 7] },
    totalBalance: [total, btc, eth, usd, eur, gbp],
    cryptoWallet: {
      totalBalance: 1233,
      coldWallet: [btc, eth, usd, total],
      hotWallet: [btc, eth, usd, total],
    },
    fiatWallet: {
      totalBalance: 45435,
      lamaFunds: gbp,
      clientFunds: [total, gbp, eur],
    },
  };
  const customer: Customer = { firstName: 'user', lastName: 'teste', email: 'user@teste.com' };
  const pendingIssues: PendingIssues = {
    reference: 1,
    date: new Date().toISOString(),
    customer,
    issue: Issue.KYC_ALERT,
    status: IssueStatus.PENDING,
  };
  const stakingPrograms: StakingPrograms = {
    reference: 1,
    date: new Date().toISOString(),
    lockTimeLeft: '123 days',
    participants: 4432,
    totalStakedAmount: btc,
  };
  const withdrawalsQueue: WithdrawalsQueue = {
    reference: 1,
    date: new Date().toISOString(),
    customer,
    method: Method.CREDIT_CARD,
    total: 1321224,
    status: IssueStatus.PENDING,
  };
  const cryptoRateExchange: CryptoRateExchange = {
    reference: 1,
    exchange: 'Binance',
    crypto: CurrencyTypes.BTC,
    rateEuro: { type: CurrencyTypes.EURO, value: 213, rate: { type: CurrencyTypes.EURO, value: 321 } },
    rateLibra: btc,
  };
  const array = [0, 1, 2, 3, 4];

  mock.onGet(`${apiPrefix}/dashboard`).reply(() => [200, { data: dashboard }]);
  mock
    .onGet(/dashboard\/pending_issues\/?.*/)
    .reply(() => [200, { data: array.map((reference) => ({ ...pendingIssues, reference })), metadata: { total: 5 } }]);
  mock
    .onGet(/dashboard\/staking_programs\/?.*/)
    .reply(() => [
      200,
      { data: array.map((reference) => ({ ...stakingPrograms, reference })), metadata: { total: 5 } },
    ]);
  mock
    .onGet(/dashboard\/withdrawls_queue\/?.*/)
    .reply(() => [
      200,
      { data: array.map((reference) => ({ ...withdrawalsQueue, reference })), metadata: { total: 5 } },
    ]);
  mock
    .onGet(/dashboard\/crypto_rate_exchange\/?.*/)
    .reply(() => [
      200,
      { data: array.map((reference) => ({ ...cryptoRateExchange, reference })), metadata: { total: 5 } },
    ]);
};
export default handleMock;
