import { UserState } from '../types/state';
import initialRequest from '../../common/services/handleInitialRequest';
import { initialPaginationResponse } from '../../common/services/handlePagination';

export default {
  deposit: initialPaginationResponse(),
  depositRequest: initialRequest,
  game: initialPaginationResponse(),
  gameRequest: initialRequest,
  network: initialPaginationResponse(),
  networkRequest: initialRequest,
  rank: {},
  rankRequest: initialRequest,
  request: initialRequest,
  totalUsers: {},
  totalUsersRequest: initialRequest,
  user: {},
  userRequest: initialRequest,
  users: initialPaginationResponse(),
  usersRequest: initialRequest,
  withdraw: initialPaginationResponse(),
  withdrawRequest: initialRequest,
  wallets: {
    eth: {
      address: '',
    },
    sol: {
      address: '',
    },
  },
  walletsRequest: initialRequest,
} as UserState;
