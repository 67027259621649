import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import AuthActionTypes from '../types/actionTypes';
import { AuthState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';

const AuthReducer: Reducer<AuthState> = (state: AuthState = initialState, action: Action): AuthState => {
  state = handleItemReducerEffect<AuthState>(
    AuthActionTypes.REFRESH_TOKEN,
    action,
    state,
    {
      tokens: {
        accessToken: action.payload?.data?.accessToken,
        refreshToken: action.payload?.data?.refreshToken,
      },
      isAuthenticated: true,
      refreshInProgress: true,
    },
    'refreshRequest',
  );

  state = handleItemReducerEffect<AuthState>(
    AuthActionTypes.ME,
    action,
    state,
    { me: action.payload, isAuthenticated: true },
    'meRequest',
  );

  state = handleItemReducerEffect(
    AuthActionTypes.LOGIN,
    action,
    state,
    {
      me: action.payload?.data?.me,
      tokens: {
        accessToken: action.payload?.data?.accessToken,
        refreshToken: action.payload?.data?.refreshToken,
      },
      isAuthenticated: true,
    },
    'loginRequest',
  );

  state = handleItemReducerEffect<AuthState>(AuthActionTypes.INIT, action, state, { isInitialized: true });

  state = handleItemReducerEffect<AuthState>(AuthActionTypes.LOGOUT, action, state, {
    ...initialState,
    isInitialized: true,
    meRequest: { ...initialState.request, complete: true },
  });

  state = handleItemReducerEffect<AuthState>(
    AuthActionTypes.PATCH_ME,
    action,
    state,
    { me: action.payload },
    'request',
  );

  state = [AuthActionTypes.PATCH_PASSWORD].reduce(
    (acc, cur) => handleItemReducerEffect(cur, action, acc, {}, 'request'),
    state,
  );

  return state;
};

export default AuthReducer;
