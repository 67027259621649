import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormikProps, useFormik } from 'formik';

import handleSchema from '../services/login/handleSchema';
import { LoginForm } from '../types/form';
import { setLogin } from '../store/actions';
import { selectAuth } from '../store/selectors';
import { Schema } from '../../common/types/schema';
import { LoginRequest } from '../types/requests';

interface LoginHooksProps {
  formik: FormikProps<LoginRequest>;
  pending: boolean;
  schema: Schema;
}

export default (): LoginHooksProps => {
  const dispatch = useDispatch();

  const {
    loginRequest: { pending },
  } = useSelector(selectAuth);

  const { validationSchema, initialValues, schema } = handleSchema();

  const onSubmit = useCallback(
    (submitValues: LoginForm) => {
      console.log('HERE');
      dispatch(setLogin(submitValues));
    },
    [dispatch],
  );

  const formik = useFormik<LoginRequest>({ validationSchema, initialValues, onSubmit });

  return { formik, pending, schema };
};
