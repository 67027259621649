import { all, takeLatest, select, put } from 'redux-saga/effects';

import AuthActionTypes from '../types/actionTypes';
import { clearTokens, storeToken } from '../../common/services/handleStorage';
import axios from '../../../configs/axios';
import handleRequest from '../../common/services/handleRequest';
import { setInit } from './actions';

const onLogout = async () => {
  try {
    await axios(handleRequest({ url: 'auth/logout', method: 'get' }, undefined));
  } catch (e) {
    // Do nothing
  }
};

function* saveTokens() {
  const {
    tokens: { accessToken, refreshToken },
  } = yield select(({ auth }) => auth);

  if (accessToken && refreshToken) yield storeToken(accessToken, refreshToken);
}

function* initialize() {
  yield put(setInit());
}

function* logout() {
  yield onLogout();

  clearTokens();

  yield;
}

export default function* authSagas() {
  yield all([
    takeLatest(AuthActionTypes.LOGOUT, logout),
    takeLatest(`${AuthActionTypes.LOGIN}_SUCCESS`, saveTokens),
    takeLatest(`${AuthActionTypes.REFRESH_TOKEN}_SUCCESS`, saveTokens),
    takeLatest(`${AuthActionTypes.ME}_SUCCESS`, initialize),
  ]);
}
