import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SplashScreen from './app/common/components/splashScreen';
import useSettings from './app/common/hooks/useSettings';
import routes from './configs/routes';
import { selectAuth } from './app/auth/store/selectors';
import ThemeContext from './contexts/themeContext';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isInitialized } = useSelector(selectAuth);

  return <ThemeContext>{isInitialized ? content : <SplashScreen {...settings} />}</ThemeContext>;
};

export default App;
