import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ContentProps } from '../../types/content';
import useSettings from '../../hooks/useSettings';

const Content: FC<ContentProps> = ({
  breadcrumb,
  children,
  customTitle,
  emptyContent,
  extraItem,
  // helmetTitle,
  title,
}: ContentProps) => {
  const { settings } = useSettings();

  return (
    <>
      {/* <Helmet>
        <title>{`${helmetTitle} | Destro Admin`}</title>
      </Helmet> */}

      {emptyContent ? (
        children
      ) : (
        <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8, pb: 14 }}>
          <Container maxWidth={settings.compact ? 'xl' : false}>
            <Grid container spacing={3}>
              <Grid item xs={12} justifyContent="space-between">
                {title ? (
                  <Typography color="textPrimary" variant="h5" data-testid="title">
                    {title}
                  </Typography>
                ) : (
                  customTitle
                )}
                {breadcrumb && (
                  <Breadcrumbs aria-label="breadcrumb" separator={<KeyboardArrowRightIcon />} sx={{ mt: 1 }}>
                    {breadcrumb.map((item, index) =>
                      item.link ? (
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          data-testid={`breadcrumb-${index}`}
                          key={item.title}
                          to={item.link}
                          variant="subtitle2"
                        >
                          {item.title}
                        </Link>
                      ) : (
                        <Typography
                          color="textSecondary"
                          data-testid={`breadcrumb-${index}`}
                          key={item.title}
                          variant="subtitle2"
                        >
                          {item.title}
                        </Typography>
                      ),
                    )}
                  </Breadcrumbs>
                )}
                {extraItem}
              </Grid>
            </Grid>
            {children}
          </Container>
        </Box>
      )}
    </>
  );
};

export default Content;
