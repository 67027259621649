import AxiosMockAdapter from 'axios-mock-adapter';

import authMock from '../app/auth/services/handleMock';
import userMock from '../app/user/services/handleMock';
import dashboardMock from '../app/dashboard/services/handleMock';
import rankMock from '../app/rank/services/handleMock';
import chatMock from '../app/chat/services/handleMock';
import gameMock from '../app/game/services/handleMock';
import axios from './axios';
import config from './settings';

export default (): void => {
  const apiPrefix = config.apiUrl;
  const mock = new AxiosMockAdapter(axios, { delayResponse: 1000 });

  authMock(mock, apiPrefix);
  userMock(mock);
  dashboardMock(mock, apiPrefix);
  rankMock(mock);
  chatMock(mock);
  gameMock(mock);
};
