import { put, call, all, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import axios from '../../../../configs/axios';

import handleRequest from '../../services/handleRequest';

import { RequestActionTypes } from '../../types/store/request';
import { ReducerAction } from '../../types/store/reducer';

import AuthActionTypes from '../../../auth/types/actionTypes';
import { enqueueSnackbar } from '../actions';
import { getRefreshTokenLocalStorage } from '../../services/handleStorage';
import { getRefreshToken } from '../../../auth/store/actions';
import handleError from '../../services/handleError';

let pendingRequestActions: ReducerAction[] = [];
let onRefresh = false;

interface ErrorResponse extends Partial<ReducerAction> {
  e: any;
}

function* hasFailure({ e, action, payload, url, method }: ErrorResponse) {
  if (e && e?.status === 401) {
    if (action === AuthActionTypes.REFRESH_TOKEN) {
      pendingRequestActions = [];
      onRefresh = false;

      yield put({ type: AuthActionTypes.LOGOUT });
      return false;
    } else if (!onRefresh) {
      const refreshToken: string = yield getRefreshTokenLocalStorage();

      if (refreshToken) {
        onRefresh = true;

        yield put(getRefreshToken({ refreshToken }));
      }
    }
    pendingRequestActions.push({
      type: RequestActionTypes.REQUEST,
      action: action ?? '',
      payload,
      url: url ?? '',
      method: method ?? 'GET',
    });
    return true;
  }
  return true;
}

function* handleErrorSnackbar(message: string) {
  yield put(enqueueSnackbar({ message, options: { variant: 'error' } }));
}

function handleErrors(e): any[] {
  return Object.keys(e).reduce((acc, item) => {
    const erro = handleError[item];
    if (erro) {
      if (erro instanceof String) {
        acc.push(erro);
      } else {
        acc.push(erro[e.errors[item]]);
      }
    }
    return acc;
  }, [] as any[]);
}

function* handleErrorMessage(e) {
  if (!onRefresh) {
    if (e?.message) {
      yield put(enqueueSnackbar({ message: e?.message, options: { variant: 'error' } }));
    }
    if (e?.errors) {
      yield all(handleErrors(e.errors).map((item) => handleErrorSnackbar(item)));
    }
  }
}

function* handleCallAction(i: ReducerAction) {
  yield put(i);
}

function* sagaRequest({ action, payload, url, method }: ReducerAction) {
  try {
    if (!onRefresh && pendingRequestActions.length) {
      pendingRequestActions.shift();
    }

    yield put({ type: `${action}_REQUEST`, payload });

    const { data, headers }: AxiosResponse = yield call(() => axios(handleRequest({ url, method }, payload)));

    yield put({
      type: `${action}_SUCCESS`,
      payload: headers?.metadata ? { data, metadata: JSON.parse(headers?.metadata) } : data,
    });

    if (action === AuthActionTypes.REFRESH_TOKEN && pendingRequestActions.length) {
      onRefresh = false;

      yield all(pendingRequestActions.map((x) => call(handleCallAction, x)));
    }
  } catch (e: any) {
    if (yield hasFailure({ e, action, payload, url, method })) {
      yield put({ type: `${action}_FAILURE`, payload: e?.data?.errors || e.message });
    }
    yield handleErrorMessage(e);
  }
}

export default function* requestSagas() {
  yield all([takeEvery(RequestActionTypes.REQUEST, sagaRequest)]);
}
