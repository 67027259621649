import { InitialPaginationResponse, MockPaginationResponse, MetadataPaginationResponse } from '../types/pagination';

export const DEFAULT_PAGE_SIZE = 5;
export const FIRST_PAGE = 1;

const paginationResponse: MetadataPaginationResponse = {
  self: '',
  first: '',
  previous: '',
  next: '',
  last: '',
  total: 0,
};

export const initialPaginationResponse = <T>(data: T[] = []) =>
  ({
    data,
    metadata: paginationResponse,
  }) as InitialPaginationResponse<T>;

export const handleMockPagination = (url?: string, urlPartial?: string, queryParams?: any): MockPaginationResponse => {
  const page = url ? Number(url.match(/page=([^&]*)/)?.[1] ?? 1) : FIRST_PAGE;
  const limit = url ? Number(url.match(/limit=([^&]*)/)?.[1] ?? 1) : DEFAULT_PAGE_SIZE;
  const list: number[] = [];
  const total = 48;
  const max = limit * page > total ? total : limit * page;
  const query = queryParams
    ? Object.keys(queryParams).reduce((acc, i) => (acc += queryParams[i] ? `${i}=${queryParams[i]}&` : ''), '')
    : '';
  const lastPage = Math.round(total / limit);
  const metadata: MetadataPaginationResponse = {
    self: `/${urlPartial}?${query}page=${page}&limit=${limit}`,
    first: `/${urlPartial}?${query}page=${FIRST_PAGE}&limit=${DEFAULT_PAGE_SIZE}`,
    previous: page > 1 ? `/${urlPartial}?${query}page=${page - 1}&limit=${limit}` : '',
    last: `/${urlPartial}?${query}page=${lastPage}&limit=${limit}`,
    next: page + 1 > lastPage ? '' : `/${urlPartial}?${query}page=${page + 1}&limit=${limit}`,
    total,
  };

  for (let index = (page - 1) * limit; index < max; index += 1) {
    list.push(index);
  }

  return {
    list,
    metadata: JSON.stringify(metadata),
  };
};
