import axios, { AxiosError } from 'axios';

import settings from './settings';
import { getRefreshTokenLocalStorage, getToken } from '../app/common/services/handleStorage';

const axiosApiInstance = axios.create();

const handleToken = async (props: any) => {
  let token = getToken();

  if (['auth/refresh-token', 'auth/confirm-pin'].indexOf(props.url) >= 0) {
    token = await getRefreshTokenLocalStorage();
  }

  return token && `Bearer ${token}`;
};

axiosApiInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const errorInfo = {
      status: error.response?.status,
      method: error.response?.config.method,
      url: error.response?.config.url,
      requestUrl: error.response?.request._url,
      data: error.response?.config.data,
      headers: error.response?.config.headers,
      ...(error.response?.data as AxiosError),
    };

    return Promise.reject(errorInfo);
  },
);

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const baseURL = settings.apiUrl;

    return {
      ...config,
      baseURL,
      params: config.method === 'get' && config.data,
      data: config.method !== 'get' && config.method !== 'delete' && config.data,
      headers: {
        Authorization: await handleToken(config),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': '*',
      },
    };
  },
  (error) => Promise.reject(error),
);

export default axiosApiInstance;
