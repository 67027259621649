import { createContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getToken } from '../app/common/services/handleStorage';
import { getMe, logout } from '../app/auth/store/actions';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext({});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = getToken();

        if (accessToken) {
          dispatch(getMe());
        } else {
          dispatch(logout());
        }
      } catch (err) {
        dispatch(logout());
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        platform: 'JWT',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
