import { Selector } from 'react-redux';
import { createSelector } from 'reselect';

import { RootState } from '../../../configs/reducers';
import { AuthState } from '../types/state';

interface AuthNameState extends AuthState {
  initialsName: string;
}

const authState: Selector<RootState, AuthState> = ({ auth }) => auth;

export const selectAuth: Selector<RootState, AuthState> = createSelector(authState, (auth) => auth);

export const selectAuthNames: Selector<RootState, AuthNameState> = createSelector(authState, (auth) => ({
  ...auth,
  initialsName: auth.me.firstName && auth.me.lastName && `${auth.me.firstName[0]}${auth.me.lastName[0]}`,
}));
