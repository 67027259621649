import type { FC, ReactNode } from 'react';
import ModalProvider from 'mui-modal-provider';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import GlobalStyles from '../theme/GlobalStyles';
import RTL from '../app/common/components/rtl';
import useScrollReset from '../app/common/hooks/useScrollReset';
import useNotifier from '../app/common/hooks/useNotifier';
import useSettings from '../app/common/hooks/useSettings';
import { createThemeProject } from '../theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface ThemeContextProps {
  children: ReactNode;
}

const ThemeContext: FC<ThemeContextProps> = ({ children }) => {
  const { settings } = useSettings();
  useScrollReset();
  useNotifier();

  const theme = createThemeProject({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ModalProvider beta>
          <RTL direction={settings.direction ?? 'ltr'}>
            <GlobalStyles />
            {children}
          </RTL>
        </ModalProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeContext;
