import { Reducer } from 'redux';

import { Action } from '../types/store/action';
import CommomActionTypes from '../types/actionTypes';
import { CommomState } from '../types/state';
import initialState from '../services/handleInitialState';
import SocketActionTypes from '../types/socket';

const CommonReducer: Reducer<CommomState> = (state: CommomState = initialState, action: Action): CommomState => {
  if (action.type === `${SocketActionTypes.EVENT_PREFIX}NOTIFICATION`) {
    state = {
      ...state,
      notifications: action.payload || [],
    };
  }

  switch (action.type) {
    case CommomActionTypes.ENQUEUE_SNACKBAR:
      return {
        ...state,
        snackbar: [...state.snackbar, action.payload],
      };

    case CommomActionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: state.snackbar.map((snackbar) =>
          action.payload.dismissAll || snackbar.key === action.payload.key
            ? { ...snackbar, dismissed: true }
            : { ...snackbar },
        ),
      };

    case CommomActionTypes.REMOVE_SNACKBAR:
      return {
        ...state,
        snackbar: state.snackbar.filter((snackbar) => snackbar.key !== action.payload),
      };

    default:
      return state;
  }
};

export default CommonReducer;
