import settings from '../../../configs/settings';

const TOKEN_STORAGE = 'accessToken';
const REFRESH_TOKEN_STORAGE = 'refreshToken';

let tokensCleared = false;

export const storeToken = (token: string, refreshToken: string) => {
  localStorage.setItem(TOKEN_STORAGE, token);
  localStorage.setItem(REFRESH_TOKEN_STORAGE, refreshToken);
};

export const clearTokens = (): void => {
  localStorage.removeItem(TOKEN_STORAGE);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE);
};

export const getToken = (): string | undefined => localStorage.getItem(TOKEN_STORAGE) || undefined;

// export const getRefreshToken = (): string | undefined => localStorage.getItem(REFRESH_TOKEN_STORAGE) || undefined;

export async function getRefreshTokenLocalStorage(): Promise<string | undefined> {
  if (settings.clearAuthenticated && !tokensCleared) {
    tokensCleared = true;
    await clearTokens();
  }
  return localStorage.getItem(REFRESH_TOKEN_STORAGE) ?? undefined;
}
