import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import ChatActionTypes from '../types/actionTypes';
import { ChatState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';
import { initialPaginationResponse } from '../../common/services/handlePagination';

const UserReducer: Reducer<ChatState> = (state: ChatState = initialState, action: Action): ChatState => {
  state = handleItemReducerEffect<ChatState>(
    ChatActionTypes.GET_CHAT,
    action,
    state,
    { chat: action.payload || initialPaginationResponse() },
    'chatRequest',
    null as any,
    { chat: initialPaginationResponse() },
  );

  return state;
};

export default UserReducer;
