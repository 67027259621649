import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountPopover from '../popover/AccountPopover';
import Logo from '../logo';
import NotificationsPopover from '../popover/NotificationsPopover';

interface MainNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer - 1,
}));

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <MainNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <RouterLink to="/">
            <Logo height={30} display="flex" />
          </RouterLink>
        </Box>
        <Box sx={{ flexGrow: 1, ml: 2 }} />
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </MainNavbarRoot>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
