import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import GameActionTypes from '../types/actionTypes';
import { GameState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';
import { initialPaginationResponse } from '../../common/services/handlePagination';

const GameReducer: Reducer<GameState> = (state: GameState = initialState, action: Action): GameState => {
  state = handleItemReducerEffect<GameState>(
    GameActionTypes.GET_GAMES,
    action,
    state,
    { games: action.payload || initialPaginationResponse() },
    'gamesRequest',
    undefined,
    { games: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<GameState>(
    GameActionTypes.GET_GAME,
    action,
    state,
    { game: action.payload },
    'gameRequest',
    undefined,
    { game: {} },
  );

  state = handleItemReducerEffect<GameState>(
    GameActionTypes.GET_GAME_BETS,
    action,
    state,
    { bets: action.payload || initialPaginationResponse() },
    'betsRequest',
    undefined,
    { bets: initialPaginationResponse() },
  );

  return state;
};

export default GameReducer;
