enum UserActionTypes {
  GET_TOTAL_USERS = '@user/GET_TOTAL_USERS',
  GET_USER = '@user/GET_USER',
  GET_USER_RANK = '@user/GET_USER_RANK',
  GET_USER_NETWORK = '@user/GET_USER_NETWORK',
  GET_USER_DEPOSIT = '@user/GET_USER_DEPOSIT',
  GET_USER_WITHDRAW = '@user/GET_USER_WITHDRAW',
  GET_USER_WALLETS = '@user/GET_USER_WALLET',
  GET_USER_GAME = '@user/GET_USER_GAME',
  GET_USERS = '@user/GET_USERS',
  EDIT_USER = '@user/EDIT_USER',
  PUT_USER_BLOCK = '@user/PUT_USER_BLOCK',
  PUT_USER_VERIFY = '@user/PUT_USER_VERIFY',
  PUT_USER_RANK = '@user/PUT_USER_RANK',
}

export default UserActionTypes;
