import { take, race, fork, call, put, cancelled, takeEvery, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import SocketActionTypes from '../../types/socket';
import { SocketEmitAction } from '../../types/socketEmitAction';
import { connect, disconnect, getSocket, reconnect } from '../api';
import { socketEmit } from '../actions';
import { getToken } from '../../services/handleStorage';

function* listenDisconnectSaga() {
  while (true) {
    yield call(disconnect);
    yield put({ type: SocketActionTypes.DISCONNECTED, payload: null });
  }
}

function* listenConnectSaga() {
  while (true) {
    yield call(reconnect);
    yield put({ type: SocketActionTypes.CONNECTED, payload: null });
  }
}

function* emitSaga() {
  yield takeEvery(SocketActionTypes.EMIT, function* takeEmit(action: SocketEmitAction) {
    yield call(() => {
      getSocket().emit(action.name, action.payload);
    });
  });
}

function* subscribeSaga() {
  yield takeLatest(SocketActionTypes.CONNECTED, function* takeSubscribe() {
    // TODO: change implementation, subscribe and unsubscribe on it on recipients
    yield put(socketEmit('balance'));
    yield put(socketEmit('activity'));
  });
}

const createSocketChannel = (socket: any) =>
  eventChannel((emit) => {
    const handler = (event: any, data: any) => {
      emit({ event, data });
    };
    socket.on('*', handler);
    return () => {
      socket.off('*', handler);
    };
  });

function* flowSaga() {
  try {
    const socket = yield call(() => connect(getToken()));
    const socketChannel = yield call(createSocketChannel, socket);
    yield fork(listenDisconnectSaga);
    yield fork(listenConnectSaga);
    yield fork(emitSaga);
    yield fork(subscribeSaga);
    yield put({ type: SocketActionTypes.CONNECTED });
    while (true) {
      const { event, data } = yield take(socketChannel);
      yield put({ type: SocketActionTypes.EVENT_PREFIX + event.toUpperCase(), payload: data });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    if (yield cancelled()) {
      yield disconnect();
    }
  }
}

export default function* socketSagas() {
  while (true) {
    yield take(SocketActionTypes.CONNECT);
    yield race({
      task: call(flowSaga),
      cancel: take(SocketActionTypes.DISCONNECT),
    });
  }
}
