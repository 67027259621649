import CommomActionTypes from '../types/actionTypes';
import { SnackbarResponse } from '../types/responses/snackbar';
import SocketActionTypes from '../types/socket';

let index = 0;

export const socketEmit = (name: string, payload?: any) => ({
  type: SocketActionTypes.EMIT,
  name,
  payload,
});

export const enqueueSnackbar = (snackbar: SnackbarResponse) => {
  index++;

  return {
    type: CommomActionTypes.ENQUEUE_SNACKBAR,
    payload: {
      ...snackbar,
      key: index,
    },
  };
};

export const closeSnackbar = (payload?: string | number) => ({
  type: CommomActionTypes.CLOSE_SNACKBAR,
  payload: {
    dismissAll: !payload, // dismiss all if no key has been defined
    key: payload,
  },
});

export const removeSnackbar = (payload: string | number) => ({
  type: CommomActionTypes.REMOVE_SNACKBAR,
  payload,
});
