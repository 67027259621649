export enum Environments {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
}

interface ConfigProps {
  apiUrl: string;
  envName: Environments | string;
  mocks: boolean;
  clearAuthenticated: boolean;
}

const config: ConfigProps = {
  apiUrl: process.env.REACT_APP_API_URL ?? '/',
  envName: process.env.NODE_ENV ?? Environments.DEVELOPMENT,
  mocks: process.env.REACT_APP_API_MOCK === 'true',
  clearAuthenticated: process.env.CLEAR_AUTHENTICATED === 'true',
};

export default config;
