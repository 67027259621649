import MockAdapter from 'axios-mock-adapter/types';
import { GameResponse } from '../types/responses';
import { handleMockPagination } from '../../common/services/handlePagination';

const gameResponse: GameResponse = {
  uuid: 'asdf-fdsa-asdf-fdsa',
  bet: 1,
  bust: 21,
  finished: true,
  hash: 'sdfghjklqwertyuiopzxcvbnm',
  payout: 32,
  profit: 211,
};

const handleMock = (mock: MockAdapter) => {
  mock.onGet(/game\?/).reply(({ url }) => {
    const { list, metadata } = handleMockPagination(url, 'game');

    return [200, list.map((item) => ({ ...gameResponse, uuid: item.toString() })), { metadata }];
  });
  mock.onGet(/game\/?.*/).reply(() => [200, gameResponse]);
};
export default handleMock;
