import { io } from 'socket.io-client';
import config from '../../../configs/settings';

let socket: any;

export const getSocket = () => socket;

export const connect = (token?: string) => {
  socket = io(`${config.apiUrl}?jwt=${token}`);
  console.log(`wsConnect${config.apiUrl}`);
  const { onevent } = socket;

  socket.onevent = function socketOnevent(packet: any) {
    const args = packet.data || [];
    onevent.call(this, packet); // original call
    packet.data = ['*'].concat(args);
    onevent.call(this, packet); // additional call to catch-all
  };

  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });

    socket.on('exception', (data: any) => {
      console.log('exception', data);
    });

    socket.on('connect_error', (err: any) => console.log(err));
    socket.on('connect_failed', (err: any) => console.log(err));
  });
};

export const reconnect = () =>
  new Promise((resolve) => {
    socket.on('reconnect', () => {
      console.log('wsReconnect');
      resolve(socket);
    });
  });

export const disconnect = () =>
  new Promise((resolve) => {
    socket.on('disconnect', () => {
      console.log('wsDisconnect');
      resolve(socket);
    });
  });
