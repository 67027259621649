import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import RankActionTypes from '../types/actionTypes';
import { RankState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';
import { initialPaginationResponse } from '../../common/services/handlePagination';

const UserReducer: Reducer<RankState> = (state: RankState = initialState, action: Action): RankState => {
  state = handleItemReducerEffect<RankState>(
    RankActionTypes.GET_RANKS,
    action,
    state,
    { ranks: action.payload || initialPaginationResponse() },
    'ranksRequest',
    undefined,
    { ranks: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<RankState>(
    RankActionTypes.GET_RANK,
    action,
    state,
    { rank: action.payload },
    'rankRequest',
    undefined,
    { rank: {} },
  );

  state = [RankActionTypes.EDIT_RANK].reduce(
    (acc, cur) => handleItemReducerEffect(cur, action, acc, {}, 'request'),
    state,
  );

  return state;
};

export default UserReducer;
