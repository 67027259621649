import * as Yup from 'yup';
import handleSchemas from '../../../common/services/handleSchemas';
import { Schema } from '../../../common/types/schema';
import { LoginForm } from '../../types/form';

export default () => {
  const schema: Schema<LoginForm> = {
    email: {
      label: 'E-mail',
      type: 'email',
      name: 'email',
      value: '',
      validation: Yup.string().email('E-mail must be a valid e-mail').required('E-mail is required.'),
      testID: 'login-email',
    },
    password: {
      label: 'Password',
      type: 'password',
      name: 'password',
      value: '',
      validation: Yup.string()
        .min(8, 'Password must have at least 8 characters.')
        .max(20, 'Password should not contain more than 20 characters.')
        // .matches(/(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Password is invalid.')
        .required('Password is required.'),
      testID: 'login-password',
    },
  };

  return {
    validationSchema: Yup.object().shape(handleSchemas(schema, 'validation')),
    initialValues: handleSchemas(schema, 'value'),
    schema,
  };
};
