import type { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MeRole } from '../../../auth/types/enum';
import { selectAuth } from '../../../auth/store/selectors';

interface RoleGuardProps {
  children: ReactNode;
  roles: MeRole[];
}

const RoleGuard: FC<RoleGuardProps> = ({ children, roles }: RoleGuardProps) => {
  const { me } = useSelector(selectAuth);

  if (me?.role && roles.find((r) => r === me?.role)) {
    return <>{children}</>;
  }
  return <Navigate to="/401" />;
};

RoleGuard.propTypes = {
  children: PropTypes.node,
};

export default RoleGuard;
