import { ReactNode, StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { SettingsProvider } from './SettingsContext';
import store from '../configs/store';

export default ({ children }: { children: ReactNode }) => (
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
          <SettingsProvider>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }} dense maxSnack={3}>
              {children}
            </SnackbarProvider>
          </SettingsProvider>
          {/* </LocalizationProvider> */}
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>
);
