export interface Currency {
  name: string;
  shortName: string;
  symbol: string;
  precision: number;
  color: string;
}

export enum CurrencyTypes {
  BTC = 'btc',
  ETH = 'eth',
  USDT = 'usdt',
  EURO = 'eur',
  GBP = 'gbp',
  USD = 'usd',
}
