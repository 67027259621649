import type { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface LoadingButtonProps extends ButtonProps {
  pending: boolean;
  children: FC | string;
}

const LoadingButton: FC<LoadingButtonProps> = ({ pending, children, ...restButton }: LoadingButtonProps) => (
  <Button variant="contained" type="submit" {...restButton} data-testid="loading-button">
    {pending ? <CircularProgress size={24} color="secondary" data-testid="loading-svg" /> : children}
  </Button>
);

export default LoadingButton;
