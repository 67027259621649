import type { FC } from 'react';
import { Box } from '@mui/material';
import { LoginFormikProps } from '../../types/form';
import Input from '../../../common/components/form/input';
import LoadingButton from '../../../common/components/loadingButton';

const LoginJWT: FC<LoginFormikProps> = ({
  // dirty,
  errors,
  handleChange,
  handleSubmit,
  pending,
  schema,
  touched,
  values,
}: LoginFormikProps) => (
  <form noValidate onSubmit={handleSubmit}>
    <Input
      autoFocus
      errors={errors.email}
      onChange={handleChange}
      schema={schema.email}
      touched={touched.email}
      values={values.email}
    />
    <Input
      errors={errors.password}
      onChange={handleChange}
      schema={schema.password}
      touched={touched.password}
      values={values.password}
    />
    <Box sx={{ mt: 2 }}>
      <LoadingButton
        color="primary"
        // disabled={!dirty || pending || Boolean(Object.keys(errors).length)}
        fullWidth
        size="large"
        pending={pending}
      >
        Log In
      </LoadingButton>
    </Box>
  </form>
);

export default LoginJWT;
