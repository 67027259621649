import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { MeResponse } from '../../auth/types/responses';
import { selectAuth } from '../../auth/store/selectors';
import { logout } from '../../auth/store/actions';

interface AccountPopoverHooksProps {
  handleClose: () => void;
  handleOpen: () => void;
  handleLogout: () => Promise<void>;
  open: boolean;
  me: MeResponse;
}

export default (): AccountPopoverHooksProps => {
  const { me } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      dispatch(logout());
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
    }
  };

  return {
    handleClose,
    handleLogout,
    handleOpen,
    me,
    open,
  };
};
