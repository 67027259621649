import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import UserActionTypes from '../types/actionTypes';
import { UserState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';
import { initialPaginationResponse } from '../../common/services/handlePagination';

const UserReducer: Reducer<UserState> = (state: UserState = initialState, action: Action): UserState => {
  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_TOTAL_USERS,
    action,
    state,
    { totalUsers: action.payload || {} },
    'totalUsersRequest',
    undefined,
    { totalUsers: {} },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USERS,
    action,
    state,
    { users: action.payload || initialPaginationResponse() },
    'usersRequest',
    undefined,
    { users: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_DEPOSIT,
    action,
    state,
    { deposit: action.payload || initialPaginationResponse() },
    'depositRequest',
    undefined,
    { deposit: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_WITHDRAW,
    action,
    state,
    { withdraw: action.payload || initialPaginationResponse() },
    'withdrawRequest',
    undefined,
    { withdraw: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_GAME,
    action,
    state,
    { game: action.payload || initialPaginationResponse() },
    'gameRequest',
    undefined,
    { game: initialPaginationResponse() },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER,
    action,
    state,
    { user: action.payload },
    'userRequest',
    undefined,
    { user: {} },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_RANK,
    action,
    state,
    { rank: action.payload },
    'rankRequest',
    undefined,
    { rank: {} },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_WALLETS,
    action,
    state,
    { wallets: action.payload },
    'walletsRequest',
    undefined,
    {
      wallets: {
        sol: { address: '' },
        eth: { address: '' },
      },
    },
  );

  state = handleItemReducerEffect<UserState>(
    UserActionTypes.GET_USER_NETWORK,
    action,
    state,
    { network: action.payload || initialPaginationResponse() },
    'networkRequest',
    undefined,
    { network: initialPaginationResponse() },
  );

  state = [
    UserActionTypes.EDIT_USER,
    UserActionTypes.PUT_USER_BLOCK,
    UserActionTypes.PUT_USER_VERIFY,
    UserActionTypes.PUT_USER_RANK,
  ].reduce((acc, cur) => handleItemReducerEffect(cur, action, acc, {}, 'request'), state);

  return state;
};

export default UserReducer;
