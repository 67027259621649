import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './JWTContext';
import CommomContexts from './basicContexts';

export default ({ children }: { children: ReactNode }) => (
  <CommomContexts>
    <BrowserRouter>
      <AuthProvider>{children}</AuthProvider>
    </BrowserRouter>
  </CommomContexts>
);
