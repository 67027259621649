import { RankState } from '../types/state';
import initialRequest from '../../common/services/handleInitialRequest';
import { initialPaginationResponse } from '../../common/services/handlePagination';

export default {
  rank: {},
  rankRequest: initialRequest,
  ranks: initialPaginationResponse(),
  ranksRequest: initialRequest,
  request: initialRequest,
} as RankState;
