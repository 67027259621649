import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'animate.css';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './configs/reportWebVitals';
import mocks from './configs/mocks';
import config from './configs/settings';
import Contexts from './contexts';

if (config.mocks) mocks();

ReactDOM.render(
  <Contexts>
    <App />
  </Contexts>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
