import { Order } from '../types/sort';

export const applyFilters = <T>(customers: T[], query: string, filters: any, searchProps: string[]): T[] =>
  customers.filter((customer) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      searchProps.forEach((property) => {
        if (customer[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && customer[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

export const applyPagination = <T>(customers: T[], page: number, limit: number): T[] =>
  customers.slice(page * limit, page * limit + limit);

const descendingComparator = <T>(a: T, b: T, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = <T>(order: Order, orderBy: string) =>
  order === 'desc'
    ? (a: T, b: T) => descendingComparator(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy);

export const applySort = <T>(customers: T[], orderBy: string, order: Order): T[] => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis.map((el) => el[0]);
};

export const tableInitialState = { result: [], total: 0 };

export const paginationInitialState = { limit: 5, offset: 0 };
