import { useRef } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import useAccountPopover from '../../hooks/useAccountPopover';
import getInitials from '../../../../utils/getInitials';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { handleClose, handleLogout, handleOpen, me, open } = useAccountPopover();

  return (
    <>
      <Box component={ButtonBase} onClick={handleOpen} ref={anchorRef} sx={{ alignItems: 'center', display: 'flex' }}>
        <Avatar src="" sx={{ height: 32, width: 32 }}>
          {getInitials(`${me.firstName} ${me.lastName}`)}
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {`${me.firstName} ${me.lastName}`}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            Destro Admin
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/account">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
