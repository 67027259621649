import { Reducer } from 'redux';

import { Action } from '../../common/types/store/action';
import DashboardActionTypes from '../types/actionTypes';
import { DashboardState } from '../types/state';
import initialState from '../services/handleInitialState';
import handleItemReducerEffect from '../../common/services/handleItemReducerEffect';

const UserReducer: Reducer<DashboardState> = (state: DashboardState = initialState, action: Action): DashboardState => {
  state = handleItemReducerEffect<DashboardState>(
    DashboardActionTypes.GET_DASHBOARD,
    action,
    state,
    { dashboard: action.payload },
    'dashboardRequest',
  );

  state = handleItemReducerEffect<DashboardState>(
    DashboardActionTypes.GET_CRYPTO_RATE_EXCHANGE,
    action,
    state,
    { cryptoRateExchange: { result: action.payload, total: action.payload?.metadata?.total } },
    'cryptoRateExchangeRequest',
  );

  state = handleItemReducerEffect<DashboardState>(
    DashboardActionTypes.GET_PENDING_ISSUES,
    action,
    state,
    { pendingIssues: { result: action.payload, total: action.payload?.metadata?.total } },
    'pendingIssuesRequest',
  );

  state = handleItemReducerEffect<DashboardState>(
    DashboardActionTypes.GET_STAKING_PROGRAMS,
    action,
    state,
    { stakingPrograms: { result: action.payload, total: action.payload?.metadata?.total } },
    'stakingProgramsRequest',
  );

  state = handleItemReducerEffect<DashboardState>(
    DashboardActionTypes.GET_WITHDRAWALS_QUEUE,
    action,
    state,
    { withdrawalsQueue: { result: action.payload, total: action.payload?.metadata?.total } },
    'withdrawalsQueueRequest',
  );

  return state;
};

export default UserReducer;
