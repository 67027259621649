import { StandardTextFieldProps, TextField } from '@mui/material';
import { FC } from 'react';
import { Options } from '../../types/schema';

interface InputProps extends StandardTextFieldProps {
  errors: any;
  schema: Options;
  touched: any;
  values: any;
}

const Input: FC<InputProps> = ({ errors, schema, touched, values, ...inputProps }: InputProps) => (
  <TextField
    error={Boolean(touched && errors)}
    fullWidth
    helperText={(errors && touched && errors) || ''}
    inputProps={{ 'data-testid': schema.testID }}
    label={schema.label}
    margin="normal"
    name={schema.name}
    type={schema.type}
    value={values}
    variant="outlined"
    {...inputProps}
  />
);
export default Input;
