import { RequestErrors } from './errors';

export interface RequestProps {
  errors?: RequestErrors;
  pending: boolean;
  complete: boolean;
  success: boolean;
  failure: boolean;
}

export enum RequestActionTypes {
  REQUEST = '@request/REQUEST',
}
