import { combineReducers } from 'redux';

import auth from '../app/auth/store/reducer';
import user from '../app/user/store/reducer';
import rank from '../app/rank/store/reducer';
import chat from '../app/chat/store/reducer';
import game from '../app/game/store/reducer';
import dashboard from '../app/dashboard/store/reducer';
import common from '../app/common/store/reducer';
import { Action } from '../app/common/types/store/action';
import AuthActionTypes from '../app/auth/types/actionTypes';

const reducers = combineReducers({
  auth,
  common,
  dashboard,
  user,
  rank,
  chat,
  game,
});

export type RootState = ReturnType<typeof reducers>;

const rootReducer = (state: any, action: Action): RootState => {
  if (action.type === AuthActionTypes.LOGOUT) {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
