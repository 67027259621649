import type { FC } from 'react';
import { ReactComponent as LogoDestro } from '../../assets/logo.svg';

interface LogoProps {
  display?: string;
  height?: number;
  width?: number;
}

const Logo: FC<LogoProps> = ({ display, height, width }: LogoProps) => (
  <LogoDestro display={display} height={height} width={width} />
);

export default Logo;
