import { GameState } from '../types/state';
import initialRequest from '../../common/services/handleInitialRequest';
import { initialPaginationResponse } from '../../common/services/handlePagination';

export default {
  game: {},
  gameRequest: initialRequest,
  games: initialPaginationResponse(),
  gamesRequest: initialRequest,
  bets: initialPaginationResponse(),
  betsRequest: initialRequest,
  request: initialRequest,
} as GameState;
