import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      'input[type="date"]::-webkit-calendar-picker-indicator': {
        color: 'white',
        filter: 'invert(1)',
      },
    },
  }),
);

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
