enum AuthActionTypes {
  LOGOUT = '@auth/LOGOUT',
  LOGIN = '@auth/LOGIN',
  REFRESH_TOKEN = '@auth/REFRESH_TOKEN',
  ME = '@auth/ME',
  PATCH_ME = '@auth/PATCH_ME',
  PATCH_PASSWORD = '@auth/PATCH_PASSWORD',
  INIT = '@auth/INIT',
}

export default AuthActionTypes;
