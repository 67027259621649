export enum Issue {
  KYC_ALERT = 'kyc_alert',
  CRYPTO_ALERT = 'crypto_alert',
  WITHDRAW_ALERT = 'withdraw_alert',
}

export enum IssueStatus {
  PENDING = 'pending',
}

export enum Method {
  CREDIT_CARD = 'credit_card',
  PAYPAL = 'paypal',
}
