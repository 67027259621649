import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Logo from '../logo';
// import NavSection from '../nav/NavSection';
import Scrollbar from '../scrollbar';
import handleMenu from '../../services/handleMenu';
import { selectAuth } from '../../../auth/store/selectors';
import getInitials from '../../../../utils/getInitials';
import NavSection from '../nav/NavSection';

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { me } = useSelector(selectAuth);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <RouterLink to="/">
              <Logo height={40} display="flex" />
            </RouterLink>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <Avatar src="" sx={{ cursor: 'pointer', height: 48, width: 48 }}>
              {getInitials(`${me.firstName} ${me.lastName}`)}
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {`${me.firstName} ${me.lastName}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {handleMenu(me.role as any).map((section) => (
            <NavSection key={section.title} pathname={location.pathname} sx={{ '& + &': { mt: 3 } }} {...section} />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280,
            },
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280,
            },
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.any,
  openMobile: PropTypes.any,
};

export default MainSidebar;
