enum SocketActionTypes {
  CONNECT = '@socket/CONNECT',
  CONNECTED = '@socket/CONNECTED',
  DISCONNECT = '@socket/DISCONNECT',
  DISCONNECTED = '@socket/DISCONNECTED',
  EMIT = '@socket/EMIT',
  EVENT_PREFIX = '@socket_',
}

export default SocketActionTypes;
