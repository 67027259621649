import MockAdapter from 'axios-mock-adapter/types';
import { CashierCurrency, CashierDepositStatus, CashierWithdrawStatus, UserStatus } from '../types/enum';
import {
  TotalUsersResponse,
  UserCashierDepositResponse,
  UserCashierWithdrawResponse,
  UserNetworkResponse,
  UserRankResponse,
  UserResponse,
} from '../types/responses';
import { GameResponse } from '../../game/types/responses';
import { handleMockPagination } from '../../common/services/handlePagination';

const hash = 'asdf-fdsa-asdf-fdsa';
const date = '2021-11-10T10:20:30.000Z';
const userResponse: UserResponse = {
  id: hash,
  userName: 'destroboy',
  firstName: 'Destro',
  lastName: 'Boy',
  birth: '1993-11-10T00:00:00.000Z',
  email: 'destro@destro.com',
  address: 'st teste, 123',
  country: 'JAPAN',
  phoneCode: 231,
  phoneNumber: 12345678,
  verified: false,
  status: UserStatus.APPROVED,

  totalBalance: 123,
  totalBet: 123,
  totalDeposit: 123,
  totalWithdraw: 123,
  totalEarn: 123,
  totalReferral: 123,
};

const totalUsersResponse: TotalUsersResponse = {
  totalReckBits: 123,
  totalUsersBits: 123,
};

const userRankResponse: UserRankResponse = {
  currentRank: {
    id: 1,
    name: 'BRONZE',
  },
  ranksStatistics: [
    { minimumBets: 500, rankId: 4, rankName: 'BRONZE', totalBets: 20 },
    { minimumBets: 1000, rankId: 5, rankName: 'SILVER', totalBets: 0 },
    { minimumBets: 2000, rankId: 6, rankName: 'GOLD', totalBets: 0 },
  ],
};

const userNetworkResponse: UserNetworkResponse = {
  child: { id: '1', userName: 'userc' },
  parent: { id: '3', userName: 'userc' },
  depth: 1,
};

const userCashierDepositResponse: UserCashierDepositResponse = {
  id: 1,
  bits: 22,
  currency: CashierCurrency.BTC,
  date,
  status: CashierDepositStatus.COMPLETE,
  value: 123,
  transactionHash: hash,
};

const userCashierWithdrawResponse: UserCashierWithdrawResponse = {
  id: 1,
  bits: 22,
  currency: CashierCurrency.BTC,
  date,
  status: CashierWithdrawStatus.CONFIRMED,
  value: 123,
  transactionHash: hash,
};

const gameResponse: GameResponse = {
  uuid: 'asdf-fdsa-asdf-fdsa',
  bet: 1,
  bust: 21,
  finished: true,
  hash: 'sdfghjklqwertyuiopzxcvbnm',
  payout: 32,
  profit: 211,
};

const handleMock = (mock: MockAdapter) => {
  mock.onGet(/user\?/).reply(({ url }) => {
    const { list, metadata } = handleMockPagination(url, 'user');

    return [200, list.map((item) => ({ ...userResponse, id: item.toString() })), { metadata }];
  });
  mock.onGet(/user\/?.*\/network\?/).reply(({ url }) => {
    const id = url ? url.match(/user\/(\/?.*)\/network/)?.[1] : '';
    const { list, metadata } = handleMockPagination(url, `user/${id}/newtwork`);

    return [200, list.map((item) => ({ ...userNetworkResponse, depth: item })), { metadata }];
  });
  mock.onGet(/user\/?.*\/deposit\?/).reply(({ url }) => {
    const id = url ? url.match(/user\/(\/?.*)\/deposit/)?.[1] : '';
    const { list, metadata } = handleMockPagination(url, `user/${id}/deposit`);

    return [200, list.map((item) => ({ ...userCashierDepositResponse, id: item })), { metadata }];
  });
  mock.onGet(/user\/?.*\/withdraw\?/).reply(({ url }) => {
    const id = url ? url.match(/user\/(\/?.*)\/withdraw/)?.[1] : '';
    const { list, metadata } = handleMockPagination(url, `user/${id}/withdraw`);

    return [200, list.map((item) => ({ ...userCashierWithdrawResponse, id: item })), { metadata }];
  });
  mock.onGet(/user\/?.*\/game\?/).reply(({ url }) => {
    const id = url ? url.match(/user\/(\/?.*)\/game/)?.[1] : '';
    const { list, metadata } = handleMockPagination(url, `user/${id}/game`);

    return [200, list.map((item) => ({ ...gameResponse, uuid: item.toString() })), { metadata }];
  });
  mock.onGet(/user\/?.*\/rank/).reply(() => [200, userRankResponse]);
  mock.onGet(/user\/stats/).reply(() => [200, totalUsersResponse]);
  mock.onPatch(/user\/?.*\/profile/).reply(() => [200, true]);
  mock.onPatch(/user\/?.*\/status/).reply(() => [200, true]);
  mock.onPatch(/user\/?.*\/verify/).reply(() => [200, true]);
  mock.onPut(/user\/?.*\/rank/).reply(() => [200, true]);
  mock.onGet(/user\/?.*\/profile/).reply(() => [200, userResponse]);
};
export default handleMock;
