import { Action } from '../../common/types/store/action';
import { ReducerAction } from '../../common/types/store/reducer';
import { RequestActionTypes } from '../../common/types/store/request';
import AuthActionTypes from '../types/actionTypes';
import { LoginRequest, MeRequest, PasswordRequest } from '../types/requests';

export const getRefreshToken = (payload: any = undefined): ReducerAction => ({
  action: AuthActionTypes.REFRESH_TOKEN,
  type: RequestActionTypes.REQUEST,
  method: 'post',
  url: 'auth/refresh-token',
  payload,
});

export const logout = (): Action => ({ type: AuthActionTypes.LOGOUT });

export const setInit = (): Action => ({ type: AuthActionTypes.INIT });

export const getMe = (): ReducerAction => ({
  action: AuthActionTypes.ME,
  type: RequestActionTypes.REQUEST,
  method: 'get',
  url: 'admin/me',
  payload: null,
});

export const patchMe = (payload: MeRequest): ReducerAction => ({
  action: AuthActionTypes.PATCH_ME,
  type: RequestActionTypes.REQUEST,
  method: 'patch',
  url: 'admin/edit',
  payload,
});

export const patchPassword = (payload: PasswordRequest): ReducerAction => ({
  action: AuthActionTypes.PATCH_PASSWORD,
  type: RequestActionTypes.REQUEST,
  method: 'patch',
  url: 'admin/edit',
  payload,
});

export const setLogin = (payload: LoginRequest): ReducerAction => ({
  action: AuthActionTypes.LOGIN,
  type: RequestActionTypes.REQUEST,
  method: 'post',
  url: 'auth/signin',
  payload,
});
