import { AuthState } from '../types/state';
import initialRequest from '../../common/services/handleInitialRequest';

export default {
  me: {},
  isInitialized: false,
  isAuthenticated: false,
  refreshInProgress: false,
  request: initialRequest,
  refreshRequest: initialRequest,
  loginRequest: initialRequest,
  meRequest: initialRequest,
} as AuthState;
