import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { selectCommon } from '../store/selectors';
import { removeSnackbar } from '../store/actions';

let displayed: SnackbarKey[] = [];
const useNotifier = () => {
  const dispatch = useDispatch();
  const { snackbar } = useSelector(selectCommon);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    snackbar.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (!key || displayed.find((i) => i === key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          displayed = [...displayed.filter((id) => id !== myKey)];
          closeSnackbar(myKey);
        },
        action: (e) => (
          <IconButton onClick={() => closeSnackbar(e)}>
            <CloseIcon />
          </IconButton>
        ),
      });

      displayed.push(key);
    });
  }, [snackbar, displayed, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
