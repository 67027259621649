import MockAdapter from 'axios-mock-adapter/types';
import { ChatResponse } from '../types/responses';
import { handleMockPagination } from '../../common/services/handlePagination';

const chatResponse: ChatResponse = {
  id: 1,
  message: 'message test',
  time: '2021-11-11T21:22:23.000Z',
  userName: 'Destro Boy',
};

const handleMock = (mock: MockAdapter) => {
  mock.onGet(/chat\?/).reply(({ url }) => {
    const { list, metadata } = handleMockPagination(url, 'chat');

    return [200, list.map((item) => ({ ...chatResponse, id: item.toString() })), { metadata }];
  });
};
export default handleMock;
