import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from '../app/common/components/guard/AuthGuard';
import MainLayout from '../app/common/components/layout/MainLayout';
import GuestGuard from '../app/common/components/guard/GuestGuard';
import RoleGuard from '../app/common/components/guard/RoleGuard';
import LoadingScreen from '../app/common/components/login';
import { MeRole } from '../app/auth/types/enum';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import('../app/auth/pages/login')));
const Account = Loadable(lazy(() => import('../app/auth/pages/account')));
const UserList = Loadable(lazy(() => import('../app/user/pages/list')));
const UserEdit = Loadable(lazy(() => import('../app/user/pages/edit')));
const UserDetail = Loadable(lazy(() => import('../app/user/pages/detail')));
const RankList = Loadable(lazy(() => import('../app/rank/pages/list')));
const RankEdit = Loadable(lazy(() => import('../app/rank/pages/edit')));
const RankDetail = Loadable(lazy(() => import('../app/rank/pages/detail')));
const Dashboard = Loadable(lazy(() => import('../app/dashboard/pages')));
const Chat = Loadable(lazy(() => import('../app/chat/pages')));
const GameList = Loadable(lazy(() => import('../app/game/pages/list')));
const GameDetail = Loadable(lazy(() => import('../app/game/pages/detail')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../app/common/pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('../app/common/pages/NotFound')));
const ServerError = Loadable(lazy(() => import('../app/common/pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'login-unguarded',
        element: <Login />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
            <Dashboard />
          </RoleGuard>
        ),
      },
      {
        path: 'chat',
        element: (
          <RoleGuard roles={[MeRole.ADMIN]}>
            <Chat />
          </RoleGuard>
        ),
      },
      {
        path: 'game',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
                <GameList />
              </RoleGuard>
            ),
          },
          {
            path: ':gameId',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
                    <GameDetail />
                  </RoleGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
                <UserList />
              </RoleGuard>
            ),
          },
          {
            path: ':userId',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
                    <UserDetail />
                  </RoleGuard>
                ),
              },
              {
                path: 'edit',
                element: (
                  <RoleGuard roles={[MeRole.ADMIN, MeRole.SUPPORT]}>
                    <UserEdit />
                  </RoleGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'rank',
        children: [
          {
            path: '',
            element: (
              <RoleGuard roles={[MeRole.ADMIN]}>
                <RankList />
              </RoleGuard>
            ),
          },
          {
            path: ':rankId',
            children: [
              {
                path: '',
                element: (
                  <RoleGuard roles={[MeRole.ADMIN]}>
                    <RankDetail />
                  </RoleGuard>
                ),
              },
              {
                path: 'edit',
                element: (
                  <RoleGuard roles={[MeRole.ADMIN]}>
                    <RankEdit />
                  </RoleGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
