import MockAdapter from 'axios-mock-adapter/types';
import { RankResponse } from '../types/responses';
import { handleMockPagination } from '../../common/services/handlePagination';

const date = '2021-11-10T10:20:30.000Z';
const rankResponse: RankResponse = {
  minimumBets: 500,
  minimumBits: 900,
  id: 4,
  name: 'BRONZE',
  badge: 'asd',
  networkEarningDetails: {
    '1': 1,
    '2': 1,
    '3': 1,
    '4': 1,
    '5': 1,
  },
  createdAt: date,
  updatedAt: date,
};

const handleMock = (mock: MockAdapter) => {
  mock.onGet(/rank\?/).reply(({ url }) => {
    const { list, metadata } = handleMockPagination(url, 'rank');

    return [200, list.map((item) => ({ ...rankResponse, rankId: item.toString() })), { metadata }];
  });
  mock.onPatch(/rank\/?.*/).reply(() => [200, true]);
  mock.onPut(/rank\/?.*\/block/).reply(() => [200, true]);
  mock.onGet(/rank\/?.*/).reply(() => [200, rankResponse]);
};
export default handleMock;
