import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ChatIcon from '@mui/icons-material/Chat';
import HistoryIcon from '@mui/icons-material/History';
import { MeRole } from '../../auth/types/enum';

interface MenuItemsProps {
  children?: MenuItemsProps[];
  icon?: any;
  path: string;
  roles: MeRole[];
  title: string;
}

interface MenuProps {
  items: MenuItemsProps[];
  title: string;
}

const menu: MenuProps[] = [
  {
    title: 'Reports',
    items: [
      {
        title: 'Dashboard',
        path: '/',
        icon: <AssessmentIcon />,
        roles: [MeRole.ADMIN, MeRole.SUPPORT],
      },
      {
        title: 'Users',
        path: '/user',
        icon: <PeopleIcon />,
        roles: [MeRole.ADMIN, MeRole.SUPPORT],
      },
      {
        title: 'Rank',
        path: '/rank',
        icon: <EqualizerIcon />,
        roles: [MeRole.ADMIN],
      },
      {
        title: 'Game',
        path: '/game',
        icon: <HistoryIcon />,
        roles: [MeRole.ADMIN, MeRole.SUPPORT],
      },
      {
        title: 'Chat',
        path: '/chat',
        icon: <ChatIcon />,
        roles: [MeRole.ADMIN],
      },
    ],
  },
];

const handleFilterMenu = (items: MenuItemsProps[], role: MeRole) =>
  items.reduce((acc, i) => {
    if (i.children && i.children.length) {
      acc.push({ ...i, childre: handleFilterMenu(i.children, role) });
    } else if (i.roles && i.roles.find((r) => r === role)) {
      acc.push(i);
    }
    return acc;
  }, [] as any[]);

export default (roles: MeRole) =>
  menu.reduce((acc: MenuProps[], i) => {
    const items = handleFilterMenu(i.items, roles);
    if (items.length) {
      acc.push({ ...i, items });
    }
    return acc;
  }, []);
