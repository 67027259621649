import MockAdapter from 'axios-mock-adapter/types';
import { MeRole } from '../types/enum';
import { LoginResponse, MeResponse } from '../types/responses';

const ACCESS_TOKEN = 'accesstoken';
const REFRESH_TOKEN = 'refreshtoken';
const meResponse: MeResponse = {
  firstName: 'User',
  lastName: 'Destro',
  email: 'user@destro.com',
  createdAt: '123',
  role: MeRole.ADMIN,
};

const loginResponse: LoginResponse = {
  accessToken: ACCESS_TOKEN,
  refreshToken: REFRESH_TOKEN,
  me: meResponse,
};

const handleMock = (mock: MockAdapter, apiPrefix: string) => {
  let chageUser = false;

  mock.onPost(`${apiPrefix}auth/signin`).reply(async (item) => {
    const { email } = JSON.parse(item.data);

    if (meResponse.email !== email) {
      return [400, { message: 'Please check your email and password' }];
    }

    return [200, loginResponse];
  });

  mock.onGet(`${apiPrefix}admin/me`).reply(() => {
    if (chageUser) {
      return [200, { data: { ...meResponse, firstName: 'teste' } }];
    }
    return [200, meResponse];
  });

  mock.onPatch(`${apiPrefix}admin/me`).reply(() => {
    chageUser = true;
    return [200, meResponse];
  });

  mock.onPatch(`${apiPrefix}admin/password`).reply(() => [200, true]);
};
export default handleMock;
